@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Belwe';
	src: url('./fonts/TT1023M.eot');
	src: local('TT1023M'), url('./fonts/TT1023M.woff') format('woff'), url('./fonts/TT1023M.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(0%) hue-rotate(347deg) brightness(103%) contrast(104%);
}

.clr{clear:both;}